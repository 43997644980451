<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12 v-if="item.notFound">
                    <v-alert outlined type="error" text> Criado na bipagem pois não foi localizado na base de dados. </v-alert>
                </v-flex>
                <v-flex xs12>
                    <v-switch v-model="item.validated" :label="$t('Validado')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Embarque')" v-model="item.shipment" />
                </v-flex>
                <v-flex xs6>
                    <v-text-field :label="$t('Número de Pacotes')" v-model="item.numberOfPackages" />
                </v-flex>
                <v-flex xs6>
                    <v-text-field :label="$t('Número de Bipagens')" v-model="item.bippedCounter" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Nome')" v-model="item.name" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Documento')" v-model="item.document" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field inputmode="numeric" v-model="item.zipCode" :rules="[rules.required, rules.cep]" :label="$t('CEP')" v-mask="'#####-###'" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Endereço')" v-model="item.address" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('NFe')" v-model="item.invoice" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('CTE')" v-model="item.cte" />
                </v-flex>

                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
                </v-flex>

                <v-flex xs12>
                    <h2>Dados de 312:</h2>
                    <pre>{{ JSON.stringify(item.data_312, null, 2) }}</pre>
                </v-flex>
                <v-flex xs12>
                    <h2>Dados de 313:</h2>
                    <pre>{{ JSON.stringify(item.data_313, null, 2) }}</pre>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                enable: true,
            },
            item: { ...this.itemClean },
            stores: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>